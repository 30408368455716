import { graphql } from 'gatsby'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { useCallback, useState, useMemo } from 'react'
import tw, { css } from 'twin.macro'
import FullImageHero from '../../components/full-image-hero'
import Heading from '../../components/heading'
import Layout from '../../components/layout'
import Profiles from '../../components/profiles'
import { global } from '../../styles/global'
import { flatten } from '../../utils/data'
import Banner from '../../components/banner'
import Profile from '../../components/profile'
import ProfileModal from '../../components/profile-modal'

const LeadersPage = ({ data: { leaders } }) => {
  const page = useMemo(
    () =>
      flatten(leaders || {}, [
        'hero',
        'introduction',
        'boardHeader',
        'leadersHeader',
        'localLeadership',
      ]),
    [leaders]
  )

  const [isOpened, setOpened] = useState(false)
  const [modalProfile, setModalProfile] = useState(null)
  const profiles = get(page, 'leaders')

  const handleProfileClick = (profile, i) => {
    if (!isOpened) {
      setModalProfile({ profile, i })
      setOpened(true)
    }
  }

  const handleClose = useCallback(() => {
    setOpened(false)
  }, [setOpened])

  const prevSlide = useCallback(
    (i) => {
      if (i > 0) setModalProfile({ i: i - 1, profile: profiles[i - 1] })
    },
    [setModalProfile, profiles]
  )

  const nextSlide = useCallback(
    (i) => {
      if (i < profiles.length - 1) setModalProfile({ i: i + 1, profile: profiles[i + 1] })
    },
    [setModalProfile, profiles]
  )

  return (
    <Layout pageTags={page.seoMetaTags} noIndex={page.noIndex} headerTransition>
      <section id="hero" css={tw`relative bg-grey-light`}>
        <FullImageHero
          image={get(page, 'hero.image')}
          imageMobile={get(page, 'hero.imageMobile')}
          title={get(page, 'hero.titleNode')}
          style={tw`lg:(mb-0 pb-52)`}
          buttonTheme="navy-cyan"
          extended
        />
      </section>
      <section id="introduction" css={tw`relative bg-grey-light pt-24 pb-16 lg:(pb-124)`}>
        <div css={[global`layout.container`, global`layout.grid`, tw`relative items-start z-1`]}>
          <div
            css={tw`col-span-4 md:col-span-8 lg:(col-start-2 col-end-13) xl:(col-start-6 col-end-15)`}
          >
            <Heading
              headingType="h3"
              content={get(page, 'introduction.titleNode')}
              style={css`
                ${tw`text-primary-500`}
                strong {
                  ${tw`font-medium`}
                }
              `}
            />
          </div>
        </div>
      </section>
      <section id="board" css={[global`layout.container`, tw`relative pt-24 lg:(-mt-96 pt-0)`]}>
        <div css={tw`flex justify-start`}>
          <Heading content={get(page, 'boardHeader.titleNode')} headingType="h2" />
        </div>
        <Profiles
          profiles={get(page, 'board')}
          nextLabel={get(page, 'nextLabel')}
          closeLabel={get(page, 'closeLabel')}
          style={tw`mt-16 mx-container-mobile mx-0 lg:(mt-14)`}
        />
      </section>
      <section id="leaders" css={[global`layout.container`, tw`pt-24`]}>
        <div css={tw`flex justify-center`}>
          <Heading
            content={get(page, 'leadersHeader.titleNode')}
            headingType="h2"
            style={tw`items-center pb-16`}
          />
        </div>

        <div
          css={[
            tw`flex flex-col flex-col-reverse
                md:(grid grid-cols-2 gap-x-12)
                lg:(grid grid-cols-6)
                xl:(grid-cols-8 )`,
          ]}
        >
          <Profile
            key={get(page, 'highlightedProfile').name}
            profile={get(page, 'highlightedProfile')}
            style={[tw`lg:(col-span-2) xl:(col-span-2 col-start-2 )`]}
            onClick={() => handleProfileClick(get(page, 'highlightedProfile'), 0)}
          />
          <Heading
            headingType="h3"
            content={get(page, 'highlightedProfileDescription')}
            style={css`
              ${tw`font-medium text-primary-500  self-center mb-8 lg:(-mt-10 col-span-4) xl:col-span-5`}
            `}
          />
        </div>

        <Profiles
          profiles={profiles}
          nextLabel={get(page, 'nextLabel')}
          closeLabel={get(page, 'closeLabel')}
          style={tw`my-16 lg:mt-14`}
        />
      </section>
      <section id="local-leadership" css={[global`layout.container`, tw`pb-20 lg:pb-32`]}>
        <Banner
          title={get(page, 'localLeadership.titleNode')}
          callToAction={get(page, 'localLeadership.callToAction')}
        />
      </section>
      <aside
        css={[
          tw`fixed inset-0 flex items-center justify-center transition duration-300 ease-in-out opacity-0 pointer-events-none top-0 -z-10 lg:top-0`,
          isOpened && tw`z-30 opacity-100 pointer-events-auto`,
        ]}
        onClick={handleClose}
        aria-hidden="true"
      >
        {modalProfile && (
          <ProfileModal
            isOpened={isOpened}
            profile={modalProfile}
            closeLabel={get(page, 'closeLabel')}
            nextLabel={get(page, 'nextLabel')}
            handleClose={handleClose}
            prevSlide={prevSlide}
            nextSlide={nextSlide}
          />
        )}
      </aside>
    </Layout>
  )
}

LeadersPage.propTypes = {
  data: PropTypes.shape({
    leaders: PropTypes.object.isRequired,
  }),
}

export default LeadersPage

export const query = graphql`
  query LeadersQuery {
    leaders: datoCmsParentLeader {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      noIndex
      hero {
        image {
          format
          url
        }
        imageMobile {
          format
          url
        }
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      introduction {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      boardHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      board {
        image {
          format
          gatsbyImageData(layout: FULL_WIDTH)
        }
        name
        jobTitle
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      leadersHeader {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
      }
      highlightedProfile {
        image {
          format
          gatsbyImageData(layout: FULL_WIDTH)
        }
        name
        jobTitle
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      highlightedProfileDescription
      leaders {
        image {
          format
          gatsbyImageData(layout: FULL_WIDTH)
        }
        name
        jobTitle
        descriptionNode {
          childMarkdownRemark {
            html
          }
        }
      }
      nextLabel
      closeLabel
      localLeadership {
        titleNode {
          childMarkdownRemark {
            html
          }
        }
        callToAction {
          label
          link
          disabled
        }
      }
    }
  }
`
